import { nanoid } from "nanoid";
import * as React from "react";
import { useSubscribe } from "replicache-react";

import { useReplicache } from "@fitstreak/frontend-replicache";
import { TrpcQueryClientProvider, trpc } from "@fitstreak/trpc-frontend";
import { Button, lightTheme } from "@fitstreak/web-components";

import "./App.css";
import reactLogo from "./assets/react.svg";
import { ENVIRONMENT } from "./environment";

function Test() {
	const hello = trpc.healthcheck.healthz.useQuery();

	if (!hello.data) return <div>Loading...</div>;
	return (
		// rome-ignore lint/a11y/useKeyWithClickEvents: fix this
		<div onClick={() => hello.refetch()}>
			<p>{hello.data.timestamp}</p>
		</div>
	);
}

function Test2() {
	const hello = trpc.exercise.list.useQuery();

	if (!hello.data) return <div>Loading...</div>;
	return (
		<>
			<h1>Exercises</h1>
			{/* rome-ignore lint/a11y/useKeyWithClickEvents: fix this */}
			<div onClick={() => hello.refetch()}>
				{hello.data.map((x) => (
					<div key={x.id}>
						<b>{x.name}</b>
						<p>{x.createdAt.toLocaleDateString()}</p>
					</div>
				))}
			</div>
		</>
	);
}

function trpcFetch(input: RequestInfo | URL, init?: RequestInit | undefined) {
	return fetch(input, {
		...init,
		credentials: "include",
	});
}

// rome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
function MessageList({ messages }: any) {
	// rome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
	return messages.map((message: any) => {
		return (
			<div key={message[0]}>
				<b>{message[1].from}: </b>
				{message[1].content}
			</div>
		);
	});
}

const styles = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	form: {
		display: "flex",
		flexDirection: "row",
		flex: 0,
		marginBottom: "1em",
	},
	username: {
		flex: 0,
		marginRight: "1em",
	},
	content: {
		flex: 1,
		maxWidth: "30em",
		margin: "0 1em",
	},
} as const;

function App() {
	const [count, setCount] = React.useState(0);

	const rep = useReplicache("default", { environment: ENVIRONMENT });

	const messages = useSubscribe(
		rep,
		async (tx) => {
			const list = await tx.scan({ prefix: "message/" }).entries().toArray();
			// rome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
			list.sort(([, a], [, b]) => (a as any)["order"] - (b as any)["order"]);
			return list;
		},
		[],
	);

	const usernameRef = React.useRef<HTMLInputElement>(null);
	const contentRef = React.useRef<HTMLInputElement>(null);

	// rome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
	const onSubmit = (e: any) => {
		e.preventDefault();

		if (!(usernameRef.current && contentRef.current)) return;

		const username = usernameRef.current.value;
		const content = contentRef.current.value;

		const last = messages.length && messages[messages.length - 1][1];
		// rome-ignore lint/suspicious/noExplicitAny: TODO: Fix this
		const order = ((last as any)?.order ?? 0) + 1;
		rep.mutate.createMessage({
			id: nanoid(),
			from: username,
			content: content,
			order,
		});
		contentRef.current.value = "";
	};

	return (
		<TrpcQueryClientProvider
			fetch={trpcFetch}
			url={new URL("trpc", ENVIRONMENT.FITSTREAK_API_URL).href}
		>
			<div className={`App ${lightTheme}`}>
				<div>
					<a href="https://vitejs.dev" target="_blank" rel="noreferrer">
						<img src="/vite.svg" className="logo" alt="Vite logo" />
					</a>
					<a href="https://reactjs.org" target="_blank" rel="noreferrer">
						<img src={reactLogo} className="logo react" alt="React logo" />
					</a>
				</div>
				<h1>Vite + React</h1>
				<Test />
				<Test2 />
				<div className="card">
					<Button
						onPress={() => setCount((count) => count + 1)}
						variant="call-to-action"
					>
						Hello
					</Button>
					<Button
						onPress={() => {
							fetch(`${ENVIRONMENT.FITSTREAK_API_URL}/healthcheck/healthz`);
						}}
						variant="call-to-action"
					>
						Hello2
					</Button>
					<p>
						Edit <code>src/App.tsx</code> and save to test {count} HMR
					</p>
				</div>
				<p className="read-the-docs">
					Click on the Vite and React logos to learn more
				</p>

				<div style={styles.container}>
					<form style={styles.form} onSubmit={onSubmit}>
						<input ref={usernameRef} style={styles.username} required />
						says:
						<input ref={contentRef} style={styles.content} required />
						<input type="submit" />
					</form>
					<MessageList messages={messages} />
				</div>
			</div>
		</TrpcQueryClientProvider>
	);
}

export default App;
