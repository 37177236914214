import Ably from "ably/promises";
import * as React from "react";
import { MutatorDefs, Replicache } from "replicache";

import {
	getReplicachePokeWebSocketChannelName,
	REPLICACHE_POKE_WEB_SOCKET_MESSAGE,
} from "@fitstreak/replicache-shared";

import { getReplicache, GetReplicacheOptions } from "./get-replicache";

export function useReplicache<M extends MutatorDefs>(
	spaceId: string,
	options: GetReplicacheOptions,
) {
	const [rep] = React.useState<Replicache<M>>(() =>
		getReplicache<M>(spaceId, options),
	);

	React.useEffect(() => {
		const ably = new Ably.Realtime.Promise({
			authUrl: `${options.environment.FITSTREAK_API_URL}/auth/create-token-request`,
		});

		const channel = ably.channels.get(
			getReplicachePokeWebSocketChannelName(spaceId),
		);

		channel.subscribe((msg) => {
			if (msg.name === REPLICACHE_POKE_WEB_SOCKET_MESSAGE) {
				rep.pull();
			}
		});

		return () => {
			channel.unsubscribe();
		};
	}, [spaceId, options.environment.FITSTREAK_API_URL, rep]);

	return rep;
}
