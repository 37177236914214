import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, HttpBatchLinkOptions } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import * as React from "react";

import type { TrpcRouter } from "@fitstreak/trpc-router";
import { transformer } from "@fitstreak/trpc-shared";

export const trpc = createTRPCReact<TrpcRouter>();

interface TrpcQueryClientProviderProps extends React.PropsWithChildren {
	url: string;
	fetch: Required<HttpBatchLinkOptions["fetch"]>;
}

export function TrpcQueryClientProvider({
	children,
	url,
	fetch,
}: TrpcQueryClientProviderProps) {
	const [queryClient] = React.useState(() => new QueryClient());
	const [trpcClient] = React.useState(() =>
		trpc.createClient({
			// Fetch could come from either react-dom or react-native
			// rome-ignore lint/suspicious/noExplicitAny: TODO: fix this
			links: [httpBatchLink({ url, fetch: fetch as any })],
			transformer,
		}),
	);

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</trpc.Provider>
	);
}
