import {
	getEnvironment,
	assertEnvironmentVariable,
} from "@fitstreak/environment";

const nodeEnv = import.meta.env.PROD ? "production" : "development";

export const ENVIRONMENT = getEnvironment(nodeEnv, {
	...(nodeEnv === "production" && {
		FITSTREAK_WEB_URL: assertEnvironmentVariable(
			"FITSTREAK_WEB_URL",
			import.meta.env.VITE_EXTERNAL_URL,
		),
	}),
});
