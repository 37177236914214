import {
	MutatorDefs,
	Replicache,
	ReplicacheOptions,
	TEST_LICENSE_KEY,
	WriteTransaction,
} from "replicache";

import type { Environment } from "@fitstreak/environment";

export interface GetReplicacheOptions {
	environment: Environment;
	config?: Pick<
		// rome-ignore lint/suspicious/noExplicitAny: TODO: fix this
		ReplicacheOptions<any>,
		"experimentalCreateKVStore"
	>;
}

export function getReplicache<M extends MutatorDefs>(
	spaceId: string,
	{ environment, config }: GetReplicacheOptions,
) {
	const rep = new Replicache<M>({
		name: spaceId,
		licenseKey: TEST_LICENSE_KEY,
		pushURL: `${environment.FITSTREAK_API_URL}/replicache/push`,
		pullURL: `${environment.FITSTREAK_API_URL}/replicache/pull`,
		mutators: {
			async createMessage(
				tx: WriteTransaction,
				{
					id,
					from,
					content,
					order,
				}: { id: string; from: string; content: string; order: number },
			) {
				await tx.put(`message/${id}`, {
					from,
					content,
					order,
				});
			},
			// rome-ignore lint/suspicious/noExplicitAny: TODO: fix this
		} as any,
		...config,
	});

	return rep;
}
