import { merge } from "remeda";

const environments = {
	production: {
		NODE_ENV: "production",
		FITSTREAK_MARKETING_SITE_URL: "https://fitstreak.xyz",
		FITSTREAK_WEB_URL: "https://app.fitstreak.xyz",
		FITSTREAK_API_URL: "https://api.fitstreak.xyz",
	},
	development: {
		NODE_ENV: "development",
		FITSTREAK_MARKETING_SITE_URL: "http://127.0.0.1:9001",
		FITSTREAK_WEB_URL: "http://127.0.0.1:9002",
		FITSTREAK_API_URL: "http://192.168.0.239:8787",
	},
	test: {
		NODE_ENV: "test",
		FITSTREAK_MARKETING_SITE_URL: "http://127.0.0.1:9001",
		FITSTREAK_WEB_URL: "http://127.0.0.1:9002",
		FITSTREAK_API_URL: "http://192.168.0.239:8787",
	},
};

export type Environments = keyof typeof environments;
export type Environment = typeof environments[Environments];

export function isEnvironment(maybeEnv?: string): maybeEnv is Environments {
	return maybeEnv !== undefined && maybeEnv in environments;
}

export function assertEnvironmentVariable(
	envVarName: string,
	envVarValue?: string,
	disable = false,
) {
	if (disable) return "*";

	if (envVarValue === undefined) {
		throw new Error(`Missing environment variable '${envVarName}'`);
	}

	return envVarValue;
}

export function getEnvironment<B>(
	env: Environments,
	overrides: B,
): Environment & B {
	return merge(environments[env], overrides);
}
