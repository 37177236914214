import { RecipeVariants } from "@vanilla-extract/recipes";
import clsx from "clsx";
import * as React from "react";
import { AriaButtonProps, useButton } from "react-aria";
import { mergeRefs } from "react-merge-refs";

import { container, tones } from "./Button.css";

interface ButtonProps extends AriaButtonProps<"button"> {
	variant?: NonNullable<
		NonNullable<RecipeVariants<typeof container>>["variant"]
	>;
	tone?: keyof typeof tones;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	function Button({ variant = "solid", tone = "primary", ...rest }, ref) {
		const innerRef = React.useRef<HTMLButtonElement>(null);
		const { buttonProps } = useButton(rest, innerRef);
		const children = rest.children;

		return (
			<button
				ref={mergeRefs([innerRef, ref])}
				{...buttonProps}
				className={clsx(tones[tone], container({ variant }))}
			>
				{children}
			</button>
		);
	},
);
